const UserMixin = {
  computed: {
    userState() {
      return this.$store.getters['UserStore/user']
    },
    userToken() {
      return this.$store.getters['UserStore/payloadToken']
    },
  },
  methods: {
    isUserAutenticated() {
      return this.$store.getters['UserStore/tokenValido']
    },
    storeUserState(token) {
      this.$store.dispatch('UserStore/logout')
      this.$store.dispatch('UserStore/autenticar', token)
    },
    logoutUser () {
      this.$store.dispatch('UserStore/logout')
    }
  }
}

export default UserMixin