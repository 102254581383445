<template>
  <div class="auth grey lighten-3">
    <v-card class="card">
      <h1 class="text-center">
        Login
      </h1>

      <div class="image">
        <img src="@/assets/logo-4-trace.png" />
      </div>
      
      <v-form>
        <v-container class="mt-4">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                ref="email"
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                @keyup.enter="nextFocus('password')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                ref="password"
                v-model="password"
                :rules="passwordRules"
                label="Senha"
                type="password"
                @keyup.enter="signin"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      
      <div class="text-center mt-8">
        <v-btn class="primary ma-auto" height="45" :disabled="!isEnabled" @click="signin">
          Entrar
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
'use strict';

import FormMixin from '@/mixins/FormMixin'
import NavigationMixin from '@/mixins/NavigationMixin'
import SnackbarMixin from '@/mixins/SnackbarMixin'
import UserMixin from '@/mixins/UserMixin'
import ValidationMixin from '@/mixins/ValidationMixin'

export default {
  mixins: [ FormMixin, NavigationMixin, SnackbarMixin, UserMixin, ValidationMixin ],
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    isEnabled () {
      if (this.isEmailValid(this.email) && this.isPasswordValid(this.password)) {
        return true;
      }

      return false;
    }
  },
  mounted () {
    this.nextFocus('email')
  },
  methods: {
    signin () {
      if (!this.isEnabled) {
        return;
      }

      this.$axios
        .post(`${this.$API_URL}/backoffice/login`, {
          email: this.email,
          password: this.password,
        }).then((response) => {
          if (response.data.codigo === 200) {
            this.onSigninSuccess(response)
          } else {
            this.onSiginError(response.data.corpo)
          }
        }).catch(() => {
          this.onSiginError('Não foi possível fazer o login')
        });
    },
    onSigninSuccess (response) {
      this.storeUserToken(response.data.corpo)
      this.goToRoute('dashboard')
    },
    onSiginError (msg) {
      this.showSnackbarErro(msg)
    },
    storeUserToken (userToken) {
      this.storeUserState(userToken)
    },
  }
};
</script>

<style scoped>
.card { width: 350px; max-width: calc(100% - 32px); padding: 16px; margin: auto; }
.auth {  min-height: 100vh; display: flex; }
h1 { font-size: 1.2rem; font-weight: 500; }
.image { margin-top: 16px; text-align: center; }
.image img { width: 180px; max-width: 100%; }
.text-center { text-align: center; }
</style>