import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import Axios from './plugins/axios';
import store from './store'
import VueMask from 'v-mask'

Vue.use(VueMask)

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
//Vue.prototype.$API_URL = 'http://127.0.0.1:8000/api'
Vue.prototype.$API_URL = 'https://api.4-trace.com.br/public/api'

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
