const ValidationMixin = {
  data: () => ({
    nameRules: [
      (v) => !!v || 'Preencha o campo',
      (v) => !v || v.length <= 50 || 'Este campo deve ter no máximo 50 dígitos',
    ],
    emailRules: [
      (v) => !!v || 'Informe o e-mail',
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'Informe um e-mail válido',
    ],
    passwordRules: [
      (v) => !!v || 'Informe a senha',
      (v) => !v || v.length >= 6 || 'A senha precisa ter pelo meno 6 dígitos',
      (v) => !v || v.length <= 20 || 'A senha deve ter no máximo 20 dígitos',
    ],
    phoneRules: [
      (v) => !!v || 'Preencha o campo',
      (v) => !v || v.length >= 10 || 'Preencha um telefone válido',
    ],
    cnpjRules: [
      (v) => !!v || 'Informe o cnpj',
      (v) => !v || v.length == 18 || 'Digite um cnpj válido',
    ],
    requiredRules: [
      (v) => !!v || 'Preencha o campo',
    ],
  }),
  methods: {
    isEmailValid(email) {
      if (!email) return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(email) ? true : false;
    },
    isPhoneNumberValid(phone) {
      return phone && (phone.length === 15 || phone.length == 16);
    },
    isPasswordValid(password) {
      if (!password) return false;
      if (password.length < 6 || password.length > 20) return false;
      return true;
    },
    isNameValid(name) {
      if (!name || name.length > 50) return false;
      return true;
    },
  },
};

export default ValidationMixin;
