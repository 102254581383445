<template>
  <v-app>
    <snackbar />
    <router-view></router-view>
  </v-app>
</template>

<script>
import Snackbar from '@/components/AppSnackbar'

export default {
  name: 'App',
  components: { Snackbar }
};
</script>

<style lang="scss">
* {
  font-family: 'Roboto', sans-serif;
}
</style>
