const NavigationMixin = {
  methods: {
    goToRoute (routeName) {
      const oldRouteName = this.$route.name
      if ( routeName != oldRouteName) {
        this.$router.push({ name: routeName })
      }
    },
    goToPath (routePath) {
      this.$router.push({ path: routePath })
    },
    goToBack () {
      this.$router.go(-1)
    }
  }
}

export default NavigationMixin