import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthView from '../views/AuthView.vue'
const CompanyView = () => import('../views/CompanyView.vue')
const DashboardView = () => import('../views/DashboardView.vue')
const LogoutView = () => import('../views/LogoutView.vue')
const PortalTemplate = () => import('../components/PortalTemplate.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/',
    component: PortalTemplate,
    children: [
      {
        path: '',
        redirect: { name: 'dashboard' }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/empresa',
        name: 'company',
        component: CompanyView,
        meta: {
          requiresAuth: true
        },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.pageName || '4 Trace'
	localStorage.setItem('lastUrl', to.path)

  let tokenValidado = false

	if (to.meta.requiresAuth) {
		let token = localStorage.token ?? null
		
		if (token) {
			let partesToken = token.split('.')

			if (partesToken.length === 3) {
				try {
					let payloadToken = JSON.parse(atob(partesToken[1]))
					let date = Math.floor(Date.now() / 1000)
					let expDate = Math.floor(new Date(payloadToken.data_expiracao) / 1000)
					if (expDate > date) tokenValidado = true			
				} catch (_) { 
          tokenValidado = false 
        }
			}
		}
	} else tokenValidado = true

	if (tokenValidado) {
		next()
	} else {
		window.location = `login`
	}
})

export default router
